export const testimonials = [
  {
    title: 'index_testimonials_swiper_items_5_title',
    content: 'index_testimonials_swiper_items_5_content',
    avatar: 'https://gurubay-front-res.s3.fr-par.scw.cloud/avatar-melanie.jpg',
    social: 'https://www.instagram.com/oana.yoga/',
    link: '/users/melanie-mfeuiauz'
  },
  {
    title: 'index_testimonials_swiper_items_2_title',
    content: 'index_testimonials_swiper_items_2_content',
    avatar: 'https://gurubay-front-res.s3.fr-par.scw.cloud/avatar-jessica.jpg',
    social: 'https://www.instagram.com/jessica_yogini/',
    link: '/users/jessica-iwubcivv'
  },
  {
    title: 'index_testimonials_swiper_items_0_title',
    content: 'index_testimonials_swiper_items_0_content',
    social: 'https://www.instagram.com/maite.popinet_yoga/',
    avatar: 'https://gurubay-front-res.s3.fr-par.scw.cloud/avatar-maite.jpg',
    link: '/users/maite-hvzwebin'
  },
  {
    title: 'index_testimonials_swiper_items_1_title',
    content: 'index_testimonials_swiper_items_1_content',
    avatar:
      'https://static-prod.gurubay.co/67_ImageType.PROFILE_7badfd5b-b7fb-434a-be07-088b17d01589.jpg',
    social: 'https://www.instagram.com/laubeyoga',
    link: '/users/julie-gythdzah'
  },
  {
    title: 'index_testimonials_swiper_items_3_title',
    content: 'index_testimonials_swiper_items_3_content',
    social: 'https://www.instagram.com/mia.y0ga',
    avatar:
      'https://static-prod.gurubay.co/122_ImageType.PROFILE_dbbb9176-b25b-48ae-a6f8-7eb2c3cd1871.jpg',
    link: '/users/michaela-aleneggj'
  },
  {
    title: 'index_testimonials_swiper_items_4_title',
    content: 'index_testimonials_swiper_items_4_content',
    social: 'https://www.instagram.com/lo_pilates/',
    avatar: 'https://gurubay-front-res.s3.fr-par.scw.cloud/avatar-laura.jpg',
    link: '/users/laura-adwpiqhn'
  }
]
