import React from 'react'
import { Container } from 'reactstrap'
import ReactIdSwiper from 'react-id-swiper'
import CardTestimonial from './CardTestimonial'
import useTranslation from '../hooks/UseTranslation'
import { testimonials } from '../data/testimonials'

const Testimonials = (props) => {
  const { data } = props
  const params = {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    roundLengths: false,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      565: {
        slidesPerView: 1
      }
    },
    pagination: {
      el: `.swiper-pagination`,
      clickable: true,
      dynamicBullets: true
    },
    autoplay: {
      delay: 4500,
      disableOnInteraction: true
    },
    // pagination: {
    //     el: `.swiper-pagination`,
    //     clickable: true,
    //     dynamicBullets: true
    // },
    containerClass: `swiper-container pt-2 pb-5`
  }
  return (
    <ReactIdSwiper {...params}>
      {data.map((item, index) => (
        <div
          key={index}
          className='px-3'
          style={{ height: 'auto', minHeight: '100%' }}
        >
          <CardTestimonial data={item} />
        </div>
      ))}
    </ReactIdSwiper>
  )
}

export const TestimonialsSection = ({ className }) => {
  const { t } = useTranslation()
  return (
    <section className={className}>
      <Container>
        <div className='text-center'>
          <p className='subtitle text-secondary'>
            {t('index_testimonials_title')}
          </p>
          <h2 className='mb-5'>{t('index_testimonials_subtitle')}</h2>
        </div>
        <Testimonials pagination t={t} data={testimonials} />
      </Container>
    </section>
  )
}

export default Testimonials
